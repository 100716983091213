import { useContext } from 'react'
import { queryCache, useMutation } from 'react-query'
import {
  CACHE_KEY_CUSTOMER,
  CACHE_KEY_UPCOMING_ORDER,
} from '~/bb-api/constants'
import { APPLY_CREDIT } from '~/bb-api/endpoints'
import { UserContext } from '~/context/user'
import axios from '~/utils/axios'
import { captureNetworkError } from '~/analytics/errors'

export default function mutateCustomerCredit() {
  const user = useContext(UserContext)
  const mutateCustomerCredit = () =>
    axios.post(APPLY_CREDIT(user.id), [user.id])

  return useMutation(mutateCustomerCredit, {
    onSuccess() {
      queryCache.invalidateQueries(CACHE_KEY_UPCOMING_ORDER)
      queryCache.invalidateQueries(CACHE_KEY_CUSTOMER)
    },
    onError(e) {
      captureNetworkError(e)
    },
  })
}
