import { Box, Grid, VisuallyHidden } from '@chakra-ui/core'
import { rem } from 'design'
import { Body, H3 } from 'design/components/Typography/Typography'
import React from 'react'
import PanelLayout, {
  ModalItemSmall,
} from '~/components/CancelFlowModals/PanelCancelLayout'
import { TEST_ID } from '~/constants/cypress'
import type * as TYPES from './PanelOfferCredit.types'
import * as Styled from './PanelOfferCredit.styles'
import StopMySubscriptionButton from '../../StopMySubscriptionButton/StopMySubscriptionButton'
import { IconBox } from '@butcherbox/freezer'

const PanelOfferCreditUI: React.FC<TYPES.PanelOfferCreditUIProps> = ({
  panel,
  formattedCredit,
  applyCreditHandler,
  isSubmitting,
}) => {
  return (
    <PanelLayout>
      <H3 fontSize={rem(21)} pb={rem(8)}>
        {panel.headline}
      </H3>
      <Body pb={rem(24)}>{panel.subhead}</Body>
      <Styled.ModalCard>
        <H3
          color="bb.spicedCrimson"
          fontSize={rem(21)}
          lineHeight={rem(20)}
          py={rem(16)}
        >
          {formattedCredit} off your next box
        </H3>
        <Box pb={rem(16)}>
          <IconBox display={'inline'} size="regular" />
        </Box>
        <Body
          lineHeight={rem(20)}
          maxWidth={rem(250)}
          pb={rem(8)}
          textAlign="center"
        >
          We're sorry your experience wasn't great. Let us make up for it next
          time.
        </Body>
      </Styled.ModalCard>

      <Grid gap={rem(16)} gridTemplateColumns="1 fr">
        <ModalItemSmall
          _hover={{ borderColor: 'bb.spicedCrimson' }}
          aria-disabled={isSubmitting}
          as="button"
          data-cy={TEST_ID.CANCEL_FLOW_SAVE}
          disabled={isSubmitting}
          flexWrap="wrap"
          onClick={() => applyCreditHandler()}
          type="button"
        >
          Yes, apply credit
        </ModalItemSmall>
        {isSubmitting && (
          <VisuallyHidden aria-live="assertive">
            submitting your credit...
          </VisuallyHidden>
        )}
        <StopMySubscriptionButton />
      </Grid>
    </PanelLayout>
  )
}

export default PanelOfferCreditUI
