import { ToastContext } from 'design/contexts/Toast/Toast.context'
import React from 'react'
import { CANCEL_SAVE_ACTIONS } from '~/analytics/constants'
import { trackCancellationSave } from '~/analytics/events'
import { ReachRouterHistoryContext } from '~/context/reachRouterHistory'
import mutateCustomerCredit from '~/hooks/mutateCustomerCredit'
import useCancelSettings from '~/hooks/useCancelSettings'
import { formatPriceWithoutTrailingZeroes } from '~/utils'
import { LoadingPanel } from '../../LoadingPanel/LoadingPanel'
import PanelOfferCreditUI from './PanelOfferCredit.ui'
import type { CancelPanelProps } from '~/components/CancelFlowPanels/CancelFlowPanel.types'

const PanelOfferCredit: React.FC<CancelPanelProps> = ({
  handleModalClose,
  panel,
}) => {
  const [isSubmitting, setIsSubmitting] = React.useState(false)
  const [formattedCredit, setFormattedCredit] = React.useState(null)
  const { getHistoryEntry } = React.useContext(ReachRouterHistoryContext)
  const showToast = React.useContext(ToastContext)
  const { data: cancelSettings } = useCancelSettings()
  const [mutateCredit] = mutateCustomerCredit()

  // Wait for cancel settings to settle, and then update the formatted price
  React.useEffect(() => {
    if (formattedCredit) return
    setFormattedCredit(
      formatPriceWithoutTrailingZeroes(cancelSettings.availableCreditAmount)
    )
  }, [cancelSettings, formattedCredit])

  const applyCreditHandler = React.useCallback(() => {
    // Toggle Submitting
    setIsSubmitting(true)
    // Invoke our Mutation
    mutateCredit(null, {
      onSuccess: () => {
        showToast('success', {
          children: `Success! ${
            cancelSettings && formattedCredit
          } credit applied to your next box.`,
        })
        trackCancellationSave(
          CANCEL_SAVE_ACTIONS.APPLY_CREDIT,
          getHistoryEntry(1).pathname,
          getHistoryEntry(2).state.text
        )
      },
      onError: () => {
        showToast('error', {
          children:
            'There was an error applying your credit, please contact customer support.',
        })
      },
      onSettled: () => {
        handleModalClose()
      },
    })
  }, [
    cancelSettings,
    formattedCredit,
    getHistoryEntry,
    handleModalClose,
    mutateCredit,
    showToast,
  ])

  return cancelSettings && !isSubmitting ? (
    <PanelOfferCreditUI
      applyCreditHandler={applyCreditHandler}
      formattedCredit={formattedCredit}
      isSubmitting={isSubmitting}
      panel={panel}
    />
  ) : (
    <LoadingPanel />
  )
}

export default PanelOfferCredit
