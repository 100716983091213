import { styled, rem } from '~/design'
import { default as PL_ModalCard } from 'design/components/ModalCard/ModalCard'

export const ModalCard = styled(PL_ModalCard)`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 100%;
  margin-bottom: ${rem(24)};
  min-height: ${rem(200)};
`
